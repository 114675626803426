<template>
    <div class="has-background-white py-4 px-4 mt-4">
       <div class="is-flex is-justify-content-space-between">
           <div class="is-size-5 has-text-weight-medium">Список отчётов</div>
           <div><input type="text" class="input" placeholder="поиск"></div>
       </div>
       <div>
        <div class="is-flex is-flex-direction-column">
            <b-table :columns="columns"></b-table>
                <h4 class="is-flex is-justify-content-center py-6">Нет данных</h4>
            <!-- :data="Reports_list" -->
        </div>
       </div>
       <div class="is-flex is-flex-direction-column	">
           <h4 class="is-uppercase has-text-weight-medium my-2">
               прикрепить отчёт
           </h4>
           <div><label for="">Наименование отчета</label></div>
           <input class="input" placeholder="Наименование отчета" type="text">
           
           <div class="file has-name my-4">
                <label class="file-label">
                    <input class="file-input" type="file" name="resume">
                    <span class="file-cta">
                    <span class="file-icon">
                        <i class="fas fa-upload"></i>
                    </span>
                    <span class="file-label">
                        Choose a file…
                    </span>
                    </span>
                    <span class="file-name">
                    Screen Shot 2017-07-29 at 15.54.25.png
                    </span>
                </label>
            </div>

           <button class="my-4 button is-success is-align-self-flex-start">Прикрепить</button>
       </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            cms_id:null,
            columns: [
                    {
                        field: 'id',
                        label: 'Номер',
                        width: '40',
                        numeric: true
                    },

                    {
                        field: 'last_name',
                        label: 'Наименование',
                    },
                    {
                        field: 'date',
                        label: 'Ссылка',
                        centered: true
                    },
                    {
                        field: 'gender',
                        label: 'Дата прикрепления',
                    }
                ]
        }
    },
    created(){
        this.loadPage()
    },
    methods:{
        async loadPage(){
            this.cms_id = this.$router.history.current.params.id.replace(/[^0-9]/g,"");
        }
    }
}
</script>

<style>

</style>

